import { useEffect, useState } from 'react';
import { addDoc, collection, onSnapshot, serverTimestamp, where, query, orderBy } from 'firebase/firestore';
import { auth, db } from '../firebase-config';
import "../styles/Chat.css";

export const Chat = (props) => {
    const { room } = props;

    const [newMessage, setNewMessage] = useState("");
    const [messages, setMessages] = useState([]);

    const messagesRef = collection(db, "messages");

    useEffect(() => {
        const queryMessages = query(messagesRef,
            where("room", "==", room),
            orderBy("createAt")
        );

        const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
            let messages = [];

            snapshot.forEach(doc => {
                messages.push({ ...doc.data(), id: doc.id });
            });

            setMessages(messages);
        });

        return () => unsuscribe();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newMessage === "") {
            return;
        } else {
            await addDoc(messagesRef, {
                text: newMessage,
                createAt: serverTimestamp(),
                user: auth.currentUser.displayName,
                room: room
            });

            setNewMessage("");
        }
    };

    return (
        <div className="chat-app">
            <div>
                {messages.map(message => <div>{message.text}</div>)}
            </div>
            <form onSubmit={handleSubmit} className="new-message-form">
                <input
                    className="new-message-input"
                    placeholder="Type Your Message Here..."
                    onChange={e => setNewMessage(e.target.value)}
                    value={newMessage}>
                </input>
                <button type="submit" className="send-button">Send</button>
            </form>
        </div>
    );
};