// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDa2c9INcV6pJRqR9WSRosOUpiZ0yqiLc8",
  authDomain: "chatapp-6eb52.firebaseapp.com",
  projectId: "chatapp-6eb52",
  storageBucket: "chatapp-6eb52.firebasestorage.app",
  messagingSenderId: "501235570116",
  appId: "1:501235570116:web:b5dc20ce5994f25629c091"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);